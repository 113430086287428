<template>
  <div class="animated fadeIn">
    <div class="card">
      <div class="card-header">
        {{title}}
      </div>
      <div class="card-body p-0">
        <div v-for="(orderItem, index) in details" class="row py-3 border mx-0" :key="index"
          :class="{ 'bg-light': index % 2 != 0 }">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <span class="font-weight-bold">Item: </span>{{orderItem.item ? (getItemReduzidoFromRef(orderItem.item.ref) + ' - ' + orderItem.item.description) : ''}}
          </div>
          <div class="col-xl-6 col-md-12 col-sm-6 col-12 mt-1">
            <span class="font-weight-bold">Quantidade: </span>{{orderItem.quantity}}
          </div>
          <div class="col-xl-6 col-md-12 col-sm-6 col-12 mt-xl-0 mt-md-3 mt-sm-0 mt-3">
            <div class="row justify-content-end h-100">
              <div class="col-xl-auto col-md-6 col-sm-auto col-6 pr-1">
                <b-button size="sm" @click.prevent="editItem(index)" :disabled="editIndex === index"
                  class="large-btn-100 medium-btn-100 mobile-btn-100">
                  <i class="fa fa-edit"></i> Editar
                </b-button>
              </div>
              <div class="col-xl-auto col-md-6 col-sm-auto col-6 pl-1">
                <b-button size="sm" variant="danger" @click.prevent="openDeleteModal(index)"
                  class="large-btn-100 medium-btn-100 mobile-btn-100">
                  <i class="fa fa-trash"></i> Remover
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Animation from '@/components/loaders/animation'
  import shared from '@/shared/shared'

  export default {
    name: 'transformation-items-card',
    components: { Animation },

    props: {
      title: {
        type: String,
        require
      },
      details: {
        type: Array,
        require
      }
    },

    data() {
      return {
        editIndex: null
      }
    },

    methods: {
      getItemReduzidoFromRef(ref) {
        return shared.getItemReduzidoFromRef(ref);
      },

      editItem(index) {
        this.$parent.editItem(this.details[index], index);
      },

      openDeleteModal(index) {
        this.$parent.openDeleteModal(index, this.details[index].movementType);
      }
    }
  };
</script>

<style scoped>
</style>
