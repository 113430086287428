<template>
  <div class="animated fadeIn">
    <div v-if="loadingTransformation" class="row justify-content-center">
      <div class="col mt-3 mb-3">
        <animation />
      </div>
    </div>

    <b-form
      v-else
      @submit.prevent="save"
      novalidate
      :validated="isValid"
      autocomplete="off"
    >
      <b-row class="mx-md-n3 mx-1 align-items-end">
        <div class="col-xl-6 col-12">
          <label for="companySelect">Selecione a Empresa:</label>
          <div v-if="loadingCompanies">
            <animation />
          </div>
          <model-list-select
            v-else
            id="companySelect"
            :list="companies"
            option-value="id"
            option-text="label"
            v-model="selectedCompany"
            placeholder="Empresa"
          />
        </div>
      </b-row>

      <b-row class="mx-md-n3 mx-1 align-items-end mt-3">
        <div class="col-sm-6 col-12">
          <label for="exitsWarehouseSelect">Selecione o Armazém das Saídas:</label>
          <div v-if="loadingWarehouses">
            <animation />
          </div>
          <model-list-select
            v-else
            id="exitsWarehouseSelect"
            :list="warehouses"
            option-value="id"
            option-text="name"
            v-model="selectedExitsWarehouse"
            placeholder="Armazém de Saída"
          />
        </div>
        <div class="col-sm-6 col-12 mt-sm-0 mt-3">
          <label for="entriesWarehouseSelect">Selecione o Armazém das Entradas:</label>
          <div v-if="loadingWarehouses">
            <animation />
          </div>
          <model-list-select
            v-else
            id="entriesWarehouseSelect"
            :list="warehouses"
            option-value="id"
            option-text="name"
            v-model="selectedEntriesWarehouse"
            placeholder="Armazém de Entrada"
          />
        </div>
      </b-row>

      <b-row class="mx-md-n3 mx-1 align-items-end mt-3">
        <div class="col-xl-6 col-12">
          <div class="row">
            <div class="col-10">
              <label for="itemSelect">Selecione o Item:</label>
              <model-list-select
                id="itemSelect"
                :list="items"
                option-value="id"
                option-text="label"
                v-model="selectedItem"
                placeholder="Digite para pesquisar..."
                @searchchange="searchItem"
                :filterPredicate="alwaysTrueFilterPredicate"
              />
            </div>
            <div class="col-2">
              <div v-if="searchItemInProcess">
                <animation/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-12 mt-xl-0 mt-3">
          <div class="row align-items-end">
            <div class="col-sm-2 col-12">
              <div class="row justify-content-center">
                <div class="col-sm-12 col-auto">
                  <b-form-radio v-model="movementType" name="movementTypeOptions" value="E" class="my-1" :disabled="editIndex !== null">
                    Entrada
                  </b-form-radio>
                </div>
                <div class="col-sm-12 col-auto">
                  <b-form-radio v-model="movementType" name="movementTypeOptions" value="S" class="my-1" :disabled="editIndex !== null">
                    Saída
                  </b-form-radio>
                </div>
              </div>
            </div>
            <div class="col-sm col-12 mt-sm-0 mt-3">
              <div class="row mx-sm-0 mr-0 align-items-end">
                <div class="col pl-xl-3 pl-sm-0 pl-3">
                  <label for="quantityInput">Quantidade:</label>
                  <b-input v-model="quantity" id="quantity" class="margin-right" onFocus="this.setSelectionRange(0, this.value.length)"></b-input>
                </div>
                <div class="col-auto pr-0">
                  <b-button variant="outline-primary" @click.prevent="addItem"
                    :disabled="!warehouseAndItemAndCompanySelected || !quantity">
                    <i v-if="editIndex == null" class="fa fa-check"></i>
                    <i v-else class="fa fa-check"></i>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-row>

      <div v-if="!formData.details.length" class="mb-3 mt-3">
        <div class="row bg-light mx-0 py-3">
          <div class="col text-center">
            Nenhum Item adicionado
          </div>
        </div>
      </div>

      <div v-else class="mb-3 mt-3">
        <div class="row">
          <div class="col-md-6 col-12">
            <transformation-items-card
              title="Saídas"
              :details="formData.details.filter(d => d.movementType === 'S')"
            ></transformation-items-card>
          </div>
          <div class="col-md-6 col-12">
            <transformation-items-card
              title="Entradas"
              :details="formData.details.filter(d => d.movementType === 'E')"
            ></transformation-items-card>
          </div>
        </div>
      </div>

      <div class="row mx-md-n3 mx-1 mt-3">
        <div class="col">
          <button-back></button-back>
          <b-button v-if="!saving" type="submit" variant="primary" class="btn-lg float-right"
            :disabled="!areBothWarehousesSelected || !isCompanySelected">
            Salvar
          </b-button>
        </div>
        <div v-if="saving" class="col-auto">
          <animation/>
        </div>
      </div>
    </b-form>

    <confirmation-modal
      id="delete-confirmation-modal"
      msg="Tem certeza de que deseja remover esse item da transformação?"
      :ok="deleteItem"
    ></confirmation-modal>
  </div>
</template>

<script>
  import CForm from './c-form'
  import ButtonBack from './c-button-back'
  import { ModelListSelect } from 'vue-search-select'
  import Animation from '@/components/loaders/animation'
  import ConfirmationModal from '@/components/common/confirmation-modal'
  import { httpClient } from '@/service'
  import shared from '@/shared/shared'
  import TransformationItemsCard from './transformation-items-card'

  export default {
    name: 'form-transformations',
    components: { Animation, CForm, ModelListSelect, ButtonBack, ConfirmationModal, TransformationItemsCard },

    data () {
      return {
        formData: {
          details: []
        },
        isValid: false,
        searchItemInProcess: false,
        items: [],
        selectedItem: {},
        quantity: '',
        editIndex: null,
        deleteIndex: null,
        warehouses: [],
        selectedEntriesWarehouse: {},
        selectedExitsWarehouse: {},
        loadingWarehouses: false,
        saving: false,
        itemSearchTimeout: null,
        loadingTransformation: false,
        movementType: 'S',
        editMovementType: null,
        selectedCompany: {},
        companies: [],
        loadingCompanies: false
      }
    },

    mounted: function () {
      this.formData = {};
      this.findTransformation();

      this.searchItem(null);
      this.getWarehouses();
      this.getCompanies();
    },

    computed: {
      isEntriesWarehouseSelected() {
        return this.selectedEntriesWarehouse
          && Object.keys(this.selectedEntriesWarehouse).length;
      },

      isExitsWarehouseSelected() {
        return this.selectedExitsWarehouse
          && Object.keys(this.selectedExitsWarehouse).length;
      },

      areBothWarehousesSelected() {
        return this.isEntriesWarehouseSelected && this.isExitsWarehouseSelected;
      },

      isItemSelected() {
        return this.selectedItem && Object.keys(this.selectedItem).length;
      },

      warehouseAndItemAndCompanySelected() {
        return this.areBothWarehousesSelected && this.isItemSelected && this.isCompanySelected;
      },

      isCompanySelected() {
        return this.selectedCompany && this.selectedCompany.id;
      }
    },

    methods: {
      async searchItem (searchText) {
        if (!this.searchItemInProcess && (
          (searchText && searchText.length) || !this.selectedItem || Object.keys(this.selectedItem).length == 0
        )) {
          if (this.itemSearchTimeout) {
            clearTimeout(this.itemSearchTimeout);
          }

          this.itemSearchTimeout = setTimeout(() => {;
            this.searchItemInProcess = true

            if (searchText && searchText.length) {
              httpClient
              .post(`${process.env.VUE_APP_API_URL}items/condition?page=${0}&size=${10}`, {
                conditions: [
                  {
                    logicalOperator: 'OR',
                    conditions: [
                      {
                        field: 'description',
                        conditionalOperator: 'LIKE_START',
                        value: searchText
                      },
                      {
                        field: 'ref',
                        conditionalOperator: 'LIKE_END',
                        value: shared.mountReduzidoFilter(searchText)
                      },
                      {
                        field: 'sku',
                        conditionalOperator: 'LIKE',
                        value: searchText
                      },
                      {
                        field: 'gtin',
                        conditionalOperator: 'LIKE',
                        value: searchText
                      }
                    ]
                  }
                ]
              })
              .then(({ data }) => {
                this.items = this.handleData(data);
                this.setItemsLabel();
              })
              .catch((error) => {
                this.searchItemInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            } else {
              httpClient
              .get(`${process.env.VUE_APP_API_URL}items/?page=${0}&size=${10}`)
              .then(({ data }) => {
                this.items = this.handleData(data);
                this.setItemsLabel();
              })
              .catch((error) => {
                this.searchItemInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            }
          }, 500);
        }
      },

      handleData(data) {
        this.searchItemInProcess = false
        this.totalRows = data.data.totalElements
        const items = data.data.content

        return items
      },

      editItem(detail, index) {
        this.editIndex = index;
        this.editMovementType = detail.movementType;
        this.selectedItem = detail.item;
        this.movementType = detail.movementType;
        this.quantity = detail.quantity;
      },

      openDeleteModal(index, movementType) {
        this.deleteIndex = index;
        this.deleteMovementType = movementType;
        this.$bvModal.show('delete-confirmation-modal');
      },

      deleteItem() {
        let index = this.findItemIndexByMovementTypeAndMovementTypeIndex(this.deleteIndex, this.deleteMovementType);
        this.formData.details.splice(index, 1);
        this.deleteIndex = null;
        let formData = this.formData;
        this.formData = {};
        this.formData = formData;
      },

      addItem () {
        let index = this.formData.details.length;

        if (this.editIndex != null) {
          let index = this.findItemIndexByMovementTypeAndMovementTypeIndex(this.editIndex, this.editMovementType);
        } else if (this.formData.details.find(dt => dt.item && dt.item.id === this.selectedItem.id && dt.movementType === this.movementType)) {
          this.$notify.textError('Esse item já foi adicionado à lista');
          return;
        }

        this.formData.details[index] = {
          quantity: Number(this.quantity.toString().replace(',', '.')),
          item: this.selectedItem,
          movementType: this.movementType
        };

        this.selectedItem = {};
        this.quantity = '';
        this.editIndex = null;
      },

      findItemIndexByMovementTypeAndMovementTypeIndex(index, movementType) {
        let movementTypeIndex = -1;

        for (let i = 0; i < this.formData.details.length; i++) {
          if (this.formData.details[i].movementType === movementType) {
            movementTypeIndex++;
          }

          if (movementTypeIndex === index) {
            return i;
          }
        }

        return -1;
      },

      save(event) {
        event.preventDefault()
        event.stopPropagation()

        this.formData.entriesStockLocale = this.selectedEntriesWarehouse;
        this.formData.exitsStockLocale = this.selectedExitsWarehouse;
        this.formData.company = this.selectedCompany;

        if (this.formData && this.formData.details) {
          this.formData.details.forEach(oi => {
            if (oi.quantity) {
              oi.quantity = Number(oi.quantity.toString().replace(',', '.'));
            }
          });
        }

        this.saving = true;
        let promise;
        if (this.formData.id) {;
          promise = httpClient.put(`${process.env.VUE_APP_API_URL}transformations/`, this.formData)
        } else {
          promise = httpClient.post(`${process.env.VUE_APP_API_URL}transformations/`, this.formData);
        }

        promise.then(data => {
          this.saving = false;
          this.$notify.success('Transformação salva com sucesso');
          this.$router.push('/orders/transformations/all');
          //this.$root.$emit('reloadOrdersCounts');
        }).catch(error => {
          this.saving = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao salvar')
          }
        });
      },

      getWarehouses() {
        this.loadingWarehouses = true;
        httpClient
        .post(`${process.env.VUE_APP_API_URL}stock-locales/condition?page=${0}&size=${100}`, {
          conditions: [{
            field: 'active',
            value: true
          }]
        }).then(({ data }) => {
          this.loadingWarehouses = false;
          this.warehouses = data.data.content;
        })
        .catch((error) => {
          this.loadingWarehouses = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError("Houve um erro ao buscar os armazéns");
          }
        });
      },

      getItemReduzidoFromRef(ref) {
        return shared.getItemReduzidoFromRef(ref);
      },

      setItemsLabel() {
        if (this.items && this.items.length) {
          this.items.forEach(item => {
            item.label = this.getItemReduzidoFromRef(item.ref) + " - " + item.description;
          });
        }
      },

      findTransformation() {
        if (this.$route.params.id) {
          this.loadingTransformation = true;
          httpClient.get(`${process.env.VUE_APP_API_URL}transformations/${this.$route.params.id}`)
          .then(data => {
            this.loadingTransformation = false;
            this.formData = data.data.data;
            this.setTransformationValues();
          }).catch(error => {
            this.loadingTransformation = false;
            if (error.message) {
              this.$notify.error(error)
            } else {
              this.$notify.textError("Houve um erro ao buscar a transformação");
            }
          });
        } else {
          this.setTransformationValues();
        }
      },

      setTransformationValues() {
        this.selectedItem = this.formData.item || {}

        if (!this.formData.details) {
          this.formData.details = [];
        }

        if (this.formData.company) {
          this.companies.push(this.formData.company);
          this.selectedCompany = this.formData.company;
        }

        if (!this.formData.entriesStockLocale) {
          let locaStorageWarehouse = shared.getLocalStorageObj('order/warehouse');

          if (locaStorageWarehouse && locaStorageWarehouse.id) {
            this.formData.entriesStockLocale = locaStorageWarehouse;
          }
        }

        if (!this.formData.exitsStockLocale) {
          let locaStorageWarehouse = shared.getLocalStorageObj('order/warehouse');

          if (locaStorageWarehouse && locaStorageWarehouse.id) {
            this.formData.exitsStockLocale = locaStorageWarehouse;
          }
        }

        if (this.formData.entriesStockLocale || this.formData.exitsStockLocale) {
          if (!this.warehouses) {
            this.warehouses = [];
          }

          if (this.formData.entriesStockLocale) {
            this.warehouses.push(this.formData.entriesStockLocale);
            this.selectedEntriesWarehouse = this.formData.entriesStockLocale;
          }

          if (this.formData.exitsStockLocale) {
            if (!this.warehouses.find(sl => sl.id === this.formData.exitsStockLocale.id)) {
              this.warehouses.push(this.formData.exitsStockLocale);
            }

            this.selectedExitsWarehouse = this.formData.exitsStockLocale;
          }
        }

        if (!this.formData.system || !this.formData.system.length) {
          this.formData.system = 'C';
        }
      },

      alwaysTrueFilterPredicate() {
        return true;
      },

      getCompanies() {
        this.loadingCompanies = true;
        httpClient
        .get(`${process.env.VUE_APP_API_URL}companies?page=${0}&size=${100}&sort=ref,asc`).then(({ data }) => {
          this.loadingCompanies = false;
          this.companies = data.data.content;
          this.setCompaniesLabels();
        }).catch((error) => {
          this.loadingCompanies = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError("Houve um erro ao buscar as empresas");
          }
        });
      },

      setCompaniesLabels() {
        if (this.companies && this.companies.length) {
          this.companies.forEach(company => {
            company.label = company.ref + (company.person ? (' - ' + company.person.name) : '')
          });
        }
      }
    }
  };
</script>

<style scoped>
</style>
