<template>
  <b-modal :id="id" @ok="ok" @hide="hide" :title="title">
    <template slot="default">
      <p style="white-space: pre-line;">{{msg}}</p>
    </template>

    <template slot="modal-footer" slot-scope="{ ok, cancel }">
      <b-button variant="secondary" @click="innerCancel" :disabled="cancelDisabled">Não</b-button>
      <b-button variant="primary" @click="ok" :disabled="okDisabled">Sim</b-button>
    </template>
  </b-modal>
</template>

<script>
  export default {
    name: 'confirmation-modal',

    props: {
      id: {
        type: String,
        require
      },
      msg: {
        type: String,
        require
      },
      title: {
        type: String,
        default() {
          return 'Confirmação';
        }
      },
      ok: {
        type: Function,
        default () {
          return;
        },
      },
      cancel: {
        type: Function,
        default () {
          return;
        },
      },
      hide: {
        type: Function,
        default(event) {
          return;
        },
      },
      okDisabled: {
        type: Boolean,
        default() {
          return false;
        }
      },
      cancelDisabled: {
        type: Boolean,
        default() {
          return false;
        }
      }
    },

    methods: {
      innerCancel() {
        this.$bvModal.hide(this.id);

        if (this.cancel) {
          this.cancel();
        }
      }
    }
  }
</script>

<style scoped>
</style>
